export enum Application {
  TOKEN = 'access_token',
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
  DOWNLOAD= 'DOWNLOAD',
  LOGIN = 'login/access-token',
  FORGET_PASSWORD ='user/forgotpassword',
  STORE_MANAGEMENT = 'store/list',
  FRAGRANCE ='store',
  PRODUCT_LIST ='product/list',
  STORE_DELETE = 'store/',
  CHANGE_PASSWORD ='user/changepassword',
  PERFUME_MANAGEMENT ='product/list',
  USER_MANAGEMENT ='customer/list',
  PERFUME_LIST ='product/list?page_num=1&page_size=1000&limit=100',
  PRODUCT_DELETE = 'product/',
  PRODUCT_DELETE_STORE = 'store/',
  PRODUCT_DETALIS_STORE = 'store/detail/',
  INGREDIENTS_LIST = 'ingredient/list',
  INGREDIENTS_ADD = 'ingredient/add',
  TAGS_LIST = 'tags/list',
  TAGS_ADD = 'tags/add',
  INGREDIENTS = 'ingredient/',
  PRODUCT_STORE_DATA ='store/product/',
  PRODUCT_STOCK_STATUS ='product/stock/',
  USER_FEEDBACK ='feedback/list',
  FEEDBACK_DELETE ='feedback/',
  RESET_PASSWORD ='user/resetpassword/',
  USER_DETAIL ='customer/',
  USER_DETAIL_FILTER ='customer/filter?limit=10000&page_size=100',
  USER_MANAGEMENT_UPDATE ='customer/',
  DOWNLOAD_REPORT ='customer/customer/export-report',
  DOWNLOAD_REPORT_Store ='store/store/export-report',
  // DOWNLOAD_REPORT_PERFUME ='product/product/export-report',
  DOWNLOAD_REPORT_PERFUME_ALL ='product/kindi/product/export-report',
  INVITE_MEMBER ='invite/list',
  INVITE_MEMBER_Add='invite/add',
  REPORT_MANAGEMENT ='report/list',
  REPORT_MANAGEMENT_Download='report/export-report',
  ADD_STORE ='store/add',
  REPORT_MANAGEMENT_DETAILS = 'report/detail/',
  UPDATE_INVITE ='invite/',
  SEARCH_PERFUME ='product/searched_perfume/',
  Recommendation_PerfumeComponent ='product/suggested_perfume/',
  suggested_PERFUME_WIDGET ='customer/suggested/perfume/',
  suggested_PERFUME_LIST ='sug/list',
  suggested_PERFUME_DETAIL = 'sug/get/{0}',
  DASHBOARD_PRODUCT ='dashboard/perfumes/report?report_type',
  DASHBOARD_STORE ='dashboard/stores/report?report_type',
  DASHBOARD_VISITORE ='dashboard/visitors/report?report_type',
  DASHBOARD_REVENUE ='dashboard/storesales/report?report_type',
  DASHBOARD_RECOMMENDED ='dashboard/recommended/perfume/report?report_type',
  DASHBOARD_BOUGHT ='dashboard/bought/report?report_type',
  TAB = -1,
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SEVEN = 7,
  EIGHT = 8,
  NINE = 9,
  TEN = 10,
  TWELVE = 12,
  THIRTEEN = 13,
  TWENTY = 20,
  TWENTY_FOUR = 24,
  THIRTY_TWO = 32,
  FOURTY_EIGHT = 48,
  FIFTY = 50,
  FIFTY_SEVEN = 57,
  SIXTY = 60,
  HUNDRED = 100,
  HUNDRED_ONE = 101,
  ONE_HUNDRED_FIFTEEN = 115,
  ONE_HUNDRED_EIGHTY = 180,
  TWO_FIVE_FIVE = 255,
  THREE_HUNDRED = 300,
  FIVE_HUNDRED = 500,
  FIVE_HUNDRED_TWO = 502,
  FOUR_HUNDRED_ONE = 401,
  FOUR_HUNDRED = 400,
  FOUR_HUNDRED_THREE = 403,
  FOUR_HUNDRED_FOUR = 404,
  TEN_THOUSAND = 10000,
  EMAIL = "EMAIL",
  PAGE_LIMIT = 10,
  CREATED_AT = "CREATED_AT",
  CRYPTO_PASSWORD = "CRYPTO_PASSWORD"
}